body { background-color: #ffffff; }
body { color: #000000; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: #000000;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: #000000;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: #000000;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: #000000;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: #000000;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: #000000;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: #000000;
font-family: 'Open Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: 25;
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
@media #{$large-up} {
font-size: 30px;

}
}
summary {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
a {color: var(--clr-79943);}
a:hover {color: #808080;}
/* Primary:2 */
.MES2 {
background-color: var(--clr-79945);
&:hover {background-color: #1a1918a5;}
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-79945);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-79945);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
cite.MEC3{
color: #ffffff;
}
/* Footer Panel:4 */
.MES4 {
border-width: 1px 0 0 0;
border-style: solid;
border-color: var(--clr-79946) transparent transparent transparent;
 }
.MES4 {
border-width: 1px 0 0 0;
border-style: solid;
border-color: var(--clr-79946) transparent transparent transparent;
 }
/* Credit Panel:5 */
.MES5 {
background-color: var(--clr-79947);
color: var(--clr-79945);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 12.8px;
};
padding: 15px;

border-width: 1px 0 0 0;
border-style: solid;
border-color: var(--clr-79948) transparent transparent transparent;
 }
.MES5 {
background-color: var(--clr-79947);
color: var(--clr-79945);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 12.8px;
};
padding: 15px;

border-width: 1px 0 0 0;
border-style: solid;
border-color: var(--clr-79948) transparent transparent transparent;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: var(--clr-79945);
 }
 }
a.MEC5 { color: var(--clr-79945);
&:hover { color: var(--clr-79949);}
 }
cite.MEC5{
color: var(--clr-79945);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 12.8px;
};
}
/* Main Menu:6 */
nav.me-Menu.MES6 {
& .menu-item.MEC6, & .menu-item.MEC6 > div.MEC6{ & > a.MEC6{color: var(--clr-79945);
text-transform: uppercase;
}
 &:hover > a.MEC6{color: var(--clr-79949);
}
 }
&.horizontal > .menu-item.MEC6 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC6 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC6 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC6 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px 20px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Home Slider:7 */
.MES7 {
& .slider-arrow {color: #ffffff;
font-size: 50px;
@media #{$medium-up} {
font-size: 80px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slider-arrow:hover{color: var(--clr-79949);
} }
/* Parallax:8 */
.MES8 {
&:hover, &.hover { background-color: #00000024;}
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://johnstoncollection.org/img/54506/46');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
background-attachment: fixed;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:120px;
@media #{$large-up} {
min-height: 90px;};
 }
.MES8 {
&:hover, &.hover { background-color: #00000024;}
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://johnstoncollection.org/img/54506/46');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
background-attachment: fixed;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:120px;
@media #{$large-up} {
min-height: 90px;};
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: #ffffff;
 }
&:hover { h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: #d8d8d84c;} }
 }
cite.MEC8{
color: #ffffff;
}
/* Footer Menu:9 */
nav.me-Menu.MES9 {
& .menu-item.MEC9, & .menu-item.MEC9 > div.MEC9{ & > a.MEC9{color: var(--clr-79945);
}
 &:hover > a.MEC9{color: var(--clr-79949);
}
 }
&.horizontal > .menu-item.MEC9 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC9 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC9 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC9 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 0;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Grey Panel:10 */
.MES10 {
background-color: var(--clr-79947);
 }
.MES10 {
background-color: var(--clr-79947);
 }
/* Hollow:11 */
.MES11 {
background-color: transparent;
&:hover {background-color: transparent;}
color: #000000;
&:hover { color: #696969;}
padding: 10px 30px;

border-width: 1px;
border-style: solid;
border-color: #000000;
&:hover { border-color: #696969; }
 }
/* Banner Strip:12 */
.MES12 {
background-color: var(--clr-79950);
 }
.MES12 {
background-color: var(--clr-79950);
 }
/* Popup Header:13 */
.MES13 {
background-color: var(--clr-79951);
padding: 15px;

 }
.MES13 {
background-color: var(--clr-79951);
padding: 15px;

 }
/* Popup Menu Panel 1:14 */
.MES14 {
background-color: var(--clr-79952);
padding: 15px;

@media #{$medium-up} {
padding: 40px 15px;

}
 }
.MES14 {
background-color: var(--clr-79952);
padding: 15px;

@media #{$medium-up} {
padding: 40px 15px;

}
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: #ffffff;
 }
 }
a.MEC14 { color: #ffffff;
&:hover { color: var(--clr-79950);}
 }
/* Popup Menu Panel 2:15 */
.MES15 {
background-color: var(--clr-79953);
padding: 15px;

@media #{$medium-up} {
padding: 40px 15px;

}
 }
.MES15 {
background-color: var(--clr-79953);
padding: 15px;

@media #{$medium-up} {
padding: 40px 15px;

}
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: #ffffff;
 }
 }
a.MEC15 { color: #ffffff;
&:hover { color: var(--clr-79950);}
 }
/* Popup Menu Close:16 */
.MES16 {
background-color: transparent;
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Responsive Menu:17 */
nav.responsive-menu {
.menu-item.MEC17{background-color: var(--clr-79951);}
& .menu-item.MEC17, & .menu-item.MEC17 > div.menu-item-wrap{ & > a.MEC17, & > i{color: #ffffff;
text-align: left;
}
  }
& .menu-item.MEC17 { border:0;
border-color: #ffffff;border-style: dotted;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 10px;}

& .sub-menu{.menu-item.MEC17{background-color: 19;}
& .menu-item.MEC17, & .menu-item.MEC17 > div.menu-item-wrap{ & > a.MEC17, & > i{color: 2;
text-align: left;
}
  }
 .sub-menu {.menu-item.MEC17{background-color: 20;}
& .menu-item.MEC17, & .menu-item.MEC17 > div.menu-item-wrap{ & > a.MEC17, & > i{color: 2;
text-align: left;
}
  }
}}

 }
/* Page Menu:18 */
nav.me-Menu.MES18 {
& .menu-item.MEC18, & .menu-item.MEC18 > div.MEC18{ & > a.MEC18{color: var(--clr-79945);
text-transform: uppercase;
}
 &:hover > a.MEC18{color: var(--clr-79949);
}
 }
&.horizontal > .menu-item.MEC18 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC18 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC18 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC18 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}

& > .menu-item.MEC18.active { & > a{ color: var(--clr-79949);}
 }

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* collection mask:19 */
.MES19 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-79949);}
color: #ffffff;
 }
.MES19 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-79949);}
color: #ffffff;
 }
cite.MEC19{
color: #ffffff;
}
/* :20 */
.MES20 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: #ffffff transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Special Event Mask:21 */
.MES21 {
background-color: #6969699e;
color: #ffffff;
padding: 15px;

 }
.MES21 {
background-color: #6969699e;
color: #ffffff;
padding: 15px;

h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: #ffffff;
 }
h1.MEC21 { @media #{$medium-up} { font-size: 48px; }; }
h1.MEC21 { @media #{$large-up} { font-size: 50px; }; }
h2.MEC21 { @media #{$medium-up} { font-size: 42px; }; }
h2.MEC21 { @media #{$large-up} { font-size: 43.75px; }; }
h3.MEC21 { @media #{$medium-up} { font-size: 36px; }; }
h3.MEC21 { @media #{$large-up} { font-size: 37.5px; }; }
h4.MEC21 { @media #{$medium-up} { font-size: 30px; }; }
h4.MEC21 { @media #{$large-up} { font-size: 31.25px; }; }
h5.MEC21 { @media #{$medium-up} { font-size: 24px; }; }
h5.MEC21 { @media #{$large-up} { font-size: 25px; }; }
h6.MEC21 { @media #{$medium-up} { font-size: 21.6px; }; }
h6.MEC21 { @media #{$large-up} { font-size: 22.5px; }; }
 }
cite.MEC21{
color: #ffffff;
}
/* Hollow White:22 */
.MES22 {
background-color: transparent;
&:hover {background-color: transparent;}
color: #ffffff;
&:hover { color: #c0c0c0;}
border-width: 1px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: #c0c0c0; }
 }
/* Carousel Dots:23 */
.MES23 {
& .slick-dots {text-align: center;
 button {margin: 0 5px;
;border-radius: 50%;
border-style:solid;
&:hover{border-color: 16;}
background-color: 17;
background-clip: padding-box;
&:hover {background-color: 16;}
width:16px;
height:16px;
@media #{$medium-up} {
width:16px;
height:16px;
};
@media #{$large-up} {
width:16px;
height:16px;
};
&:hover{background-color: 16;}
}}
 }
/* Unselected:24 */
.MES24 {
background-color: transparent;
&:hover {background-color: transparent;}
color: var(--clr-79945);
&:hover { color: var(--clr-79945);}
border-width: 1px;
border-style: solid;
border-color: var(--clr-79945);
&:hover { border-color: var(--clr-79945); }
 }
/* Page Space:25 */
.MES25 {
padding: 0;

@media #{$medium-up} {
padding: 10px;

}
 }
/* Share Button:26 */
.me-block.me-ShareButton .MES26{
figure.MEC26 { background-color: transparent;
&:hover{background-color: transparent;}
 }
figure.MEC26{ a { font-size: 30px;

color: var(--clr-79945);
 }
&:hover a {color: var(--clr-79951);}
 }

 }
/* Checkout Page Panel:27 */
.MES27 {
color: var(--clr-79945);
 }
.MES27 {
color: var(--clr-79945);
h1.MEC27, h2.MEC27, h3.MEC27, h4.MEC27, h5.MEC27, h6.MEC27 { color: var(--clr-79945);
 }
 }
a.MEC27 { color: var(--clr-79945);
&:hover { color: var(--clr-79951);}
 }
cite.MEC27{
color: var(--clr-79945);
}
/* Search Button:28 */
.MES28 {
background-color: #ffffff;
&:hover {background-color: #ffffff;}
color: var(--clr-79945);
&:hover { color: var(--clr-79949);}
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-79945);
&:hover { border-color: var(--clr-79949); }
 }
/* After Hour Event Ticket:31 */
details.MES31 {
& > summary{background-color: var(--clr-79945);
}
& > summary{padding: 10px 15px;}

& > article{padding: 15px 0;}

& > summary {& > div  > i{color: #ffffff;
font-size: 20px;
@media #{$medium-up} {
font-size: 20px;
};
@media #{$large-up} {
font-size: 20px;
};
}}
& > summary { color: #ffffff;
font-size:20px;
@media #{$medium-up} {
font-size:25px;
}
@media #{$large-up} {
font-size:20px;
}
 }
 }
/* after hour event:32 */
.MES32 {
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-79946);
 }
.MES32 {
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-79946);
 }
/* Bg Image Panel:33 */
.MES33 {
background-color: transparent;
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://johnstoncollection.org/img/50750/164');
background-position: center right;
background-repeat: no-repeat;
top: 0;
background-size: cover;
background-attachment: fixed;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}@media #{$large-up} {
min-height: 400px;};
 }
.MES33 {
background-color: transparent;
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://johnstoncollection.org/img/50750/164');
background-position: center right;
background-repeat: no-repeat;
top: 0;
background-size: cover;
background-attachment: fixed;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}@media #{$large-up} {
min-height: 400px;};
 }
/* Larger headings white:34 */
.MES34 {
color: #ffffff;
 }
.MES34 {
color: #ffffff;
h1.MEC34, h2.MEC34, h3.MEC34, h4.MEC34, h5.MEC34, h6.MEC34 { color: #ffffff;
 }
h1.MEC34 { @media #{$medium-up} { font-size: 44px; }; }
h1.MEC34 { @media #{$large-up} { font-size: 48px; }; }
h2.MEC34 { @media #{$medium-up} { font-size: 38.5px; }; }
h2.MEC34 { @media #{$large-up} { font-size: 42px; }; }
h3.MEC34 { @media #{$medium-up} { font-size: 33px; }; }
h3.MEC34 { @media #{$large-up} { font-size: 36px; }; }
h4.MEC34 { @media #{$medium-up} { font-size: 27.5px; }; }
h4.MEC34 { @media #{$large-up} { font-size: 30px; }; }
h5.MEC34 { @media #{$medium-up} { font-size: 22px; }; }
h5.MEC34 { @media #{$large-up} { font-size: 24px; }; }
h6.MEC34 { @media #{$medium-up} { font-size: 19.8px; }; }
h6.MEC34 { @media #{$large-up} { font-size: 21.6px; }; }
 }
a.MEC34 { color: #ffffff;
&:hover { color: var(--clr-79947);}
 }
cite.MEC34{
color: #ffffff;
}
/* File list:35 */
.MES35 {
color: var(--clr-79945);
&:hover { color: var(--clr-79949);}
padding: 5px 15px;

 }
/* Grey Panel:36 */
.MES36 {
background-color: var(--clr-79955);
color: #ffffff;
 }
.MES36 {
background-color: var(--clr-79955);
color: #ffffff;
h1.MEC36, h2.MEC36, h3.MEC36, h4.MEC36, h5.MEC36, h6.MEC36 { color: #ffffff;
 }
 }
a.MEC36 { color: #ffffff;
&:hover { color: var(--clr-79947);}
 }
cite.MEC36{
color: #ffffff;
}
/* Larger headings Black:37 */
.MES37 {
color: var(--clr-79945);
font-size: 14px;
@media #{$medium-up} {
font-size: 16.32px;
};
@media #{$large-up} {
font-size: 16.8px;
};
 }
.MES37 {
color: var(--clr-79945);
font-size: 14px;
@media #{$medium-up} {
font-size: 16.32px;
};
@media #{$large-up} {
font-size: 16.8px;
};
h1.MEC37, h2.MEC37, h3.MEC37, h4.MEC37, h5.MEC37, h6.MEC37 { color: var(--clr-79945);
 }
h1.MEC37 { @media #{$medium-up} { font-size: 44px; }; }
h1.MEC37 { @media #{$large-up} { font-size: 48px; }; }
h2.MEC37 { @media #{$medium-up} { font-size: 38.5px; }; }
h2.MEC37 { @media #{$large-up} { font-size: 42px; }; }
h3.MEC37 { @media #{$medium-up} { font-size: 33px; }; }
h3.MEC37 { @media #{$large-up} { font-size: 36px; }; }
h4.MEC37 { @media #{$medium-up} { font-size: 27.5px; }; }
h4.MEC37 { @media #{$large-up} { font-size: 30px; }; }
h5.MEC37 { @media #{$medium-up} { font-size: 22px; }; }
h5.MEC37 { @media #{$large-up} { font-size: 24px; }; }
h6.MEC37 { @media #{$medium-up} { font-size: 19.8px; }; }
h6.MEC37 { @media #{$large-up} { font-size: 21.6px; }; }
 }
cite.MEC37{
color: var(--clr-79945);
font-size: 14px;
@media #{$medium-up} {
font-size: 16.32px;
};
@media #{$large-up} {
font-size: 16.8px;
};
}
/* olive Panel:38 */
.MES38 {
background-color: var(--clr-79956);
color: #ffffff;
 }
.MES38 {
background-color: var(--clr-79956);
color: #ffffff;
h1.MEC38, h2.MEC38, h3.MEC38, h4.MEC38, h5.MEC38, h6.MEC38 { color: #ffffff;
 }
 }
a.MEC38 { color: #ffffff;
&:hover { color: var(--clr-79947);}
 }
cite.MEC38{
color: #ffffff;
}
/* Orange Panel:39 */
.MES39 {
background-color: var(--clr-79957);
color: #ffffff;
 }
.MES39 {
background-color: var(--clr-79957);
color: #ffffff;
h1.MEC39, h2.MEC39, h3.MEC39, h4.MEC39, h5.MEC39, h6.MEC39 { color: #ffffff;
 }
 }
a.MEC39 { color: #ffffff;
&:hover { color: var(--clr-79947);}
 }
cite.MEC39{
color: #ffffff;
}
/* Aqua Panel:40 */
.MES40 {
background-color: var(--clr-79958);
color: #ffffff;
 }
.MES40 {
background-color: var(--clr-79958);
color: #ffffff;
h1.MEC40, h2.MEC40, h3.MEC40, h4.MEC40, h5.MEC40, h6.MEC40 { color: #ffffff;
 }
 }
a.MEC40 { color: #ffffff;
&:hover { color: var(--clr-79947);}
 }
cite.MEC40{
color: #ffffff;
}
/* Blue Panel:41 */
.MES41 {
background-color: var(--clr-79959);
color: #ffffff;
 }
.MES41 {
background-color: var(--clr-79959);
color: #ffffff;
h1.MEC41, h2.MEC41, h3.MEC41, h4.MEC41, h5.MEC41, h6.MEC41 { color: #ffffff;
 }
 }
a.MEC41 { color: #ffffff;
&:hover { color: var(--clr-79947);}
 }
cite.MEC41{
color: #ffffff;
}
/* Blue Grey Panel:42 */
.MES42 {
background-color: #8fafbf;
color: #ffffff;
 }
.MES42 {
background-color: #8fafbf;
color: #ffffff;
h1.MEC42, h2.MEC42, h3.MEC42, h4.MEC42, h5.MEC42, h6.MEC42 { color: #ffffff;
 }
 }
a.MEC42 { color: #ffffff;
&:hover { color: var(--clr-79947);}
 }
cite.MEC42{
color: #ffffff;
}
/* Blue Sky Panel:43 */
.MES43 {
background-color: var(--clr-79961);
color: #ffffff;
 }
.MES43 {
background-color: var(--clr-79961);
color: #ffffff;
h1.MEC43, h2.MEC43, h3.MEC43, h4.MEC43, h5.MEC43, h6.MEC43 { color: #ffffff;
 }
 }
a.MEC43 { color: #ffffff;
&:hover { color: var(--clr-79947);}
 }
cite.MEC43{
color: #ffffff;
}
/* Salmon Panel:44 */
.MES44 {
background-color: var(--clr-79962);
color: #ffffff;
 }
.MES44 {
background-color: var(--clr-79962);
color: #ffffff;
h1.MEC44, h2.MEC44, h3.MEC44, h4.MEC44, h5.MEC44, h6.MEC44 { color: #ffffff;
 }
 }
a.MEC44 { color: #ffffff;
&:hover { color: var(--clr-79947);}
 }
cite.MEC44{
color: #ffffff;
}
/* Dark grey blue Panel:45 */
.MES45 {
background-color: var(--clr-79951);
color: #ffffff;
 }
.MES45 {
background-color: var(--clr-79951);
color: #ffffff;
h1.MEC45, h2.MEC45, h3.MEC45, h4.MEC45, h5.MEC45, h6.MEC45 { color: #ffffff;
 }
 }
a.MEC45 { color: #ffffff;
&:hover { color: var(--clr-79947);}
 }
cite.MEC45{
color: #ffffff;
}
/* Olive Green Panel:46 */
.MES46 {
background-color: var(--clr-79963);
color: #ffffff;
 }
.MES46 {
background-color: var(--clr-79963);
color: #ffffff;
h1.MEC46, h2.MEC46, h3.MEC46, h4.MEC46, h5.MEC46, h6.MEC46 { color: #ffffff;
 }
 }
a.MEC46 { color: #ffffff;
&:hover { color: var(--clr-79947);}
 }
cite.MEC46{
color: #ffffff;
}
/* Dark Green Panel:47 */
.MES47 {
background-color: var(--clr-79964);
color: #ffffff;
 }
.MES47 {
background-color: var(--clr-79964);
color: #ffffff;
h1.MEC47, h2.MEC47, h3.MEC47, h4.MEC47, h5.MEC47, h6.MEC47 { color: #ffffff;
 }
 }
a.MEC47 { color: #ffffff;
&:hover { color: var(--clr-79947);}
 }
cite.MEC47{
color: #ffffff;
}
/* Raspberry Panel:48 */
.MES48 {
background-color: var(--clr-79965);
color: #ffffff;
 }
.MES48 {
background-color: var(--clr-79965);
color: #ffffff;
h1.MEC48, h2.MEC48, h3.MEC48, h4.MEC48, h5.MEC48, h6.MEC48 { color: #ffffff;
 }
 }
a.MEC48 { color: #ffffff;
&:hover { color: var(--clr-79947);}
 }
cite.MEC48{
color: #ffffff;
}
/* Navy Panel:49 */
.MES49 {
background-color: var(--clr-79966);
color: #ffffff;
 }
.MES49 {
background-color: var(--clr-79966);
color: #ffffff;
h1.MEC49, h2.MEC49, h3.MEC49, h4.MEC49, h5.MEC49, h6.MEC49 { color: #ffffff;
 }
 }
a.MEC49 { color: #ffffff;
&:hover { color: var(--clr-79947);}
 }
cite.MEC49{
color: #ffffff;
}
/* Gold Panel:50 */
.MES50 {
background-color: var(--clr-79967);
color: #ffffff;
 }
.MES50 {
background-color: var(--clr-79967);
color: #ffffff;
h1.MEC50, h2.MEC50, h3.MEC50, h4.MEC50, h5.MEC50, h6.MEC50 { color: #ffffff;
 }
 }
a.MEC50 { color: #ffffff;
&:hover { color: var(--clr-79947);}
 }
cite.MEC50{
color: #ffffff;
}
/* Annual Report:51 */
.MES51 {
background-color: var(--clr-79968);
 }
.MES51 {
background-color: var(--clr-79968);
h1.MEC51, h2.MEC51, h3.MEC51, h4.MEC51, h5.MEC51, h6.MEC51 { color: #ffffff;
 }
 }
/* Large Heading White:52 */
.MES52 {
 }
.MES52 {
h1.MEC52, h2.MEC52, h3.MEC52, h4.MEC52, h5.MEC52, h6.MEC52 { color: #ffffff;
 }
h1.MEC52 { @media #{$large-up} { font-size: 80px; }; }
h2.MEC52 { @media #{$large-up} { font-size: 70px; }; }
h3.MEC52 { @media #{$large-up} { font-size: 60px; }; }
h4.MEC52 { @media #{$large-up} { font-size: 50px; }; }
h5.MEC52 { @media #{$large-up} { font-size: 40px; }; }
h6.MEC52 { @media #{$large-up} { font-size: 36px; }; }
 }
/* Year in review border panel:53 */
.MES53 {
border-width: 0 0 2px 2px;
border-style: solid;
border-color: transparent transparent var(--clr-79946) var(--clr-79946);
 }
.MES53 {
border-width: 0 0 2px 2px;
border-style: solid;
border-color: transparent transparent var(--clr-79946) var(--clr-79946);
 }
/* Black Panel:54 */
.MES54 {
background-color: #000000;
 }
.MES54 {
background-color: #000000;
 }
/* Prevent Admin Checkout Panel:55 */
.MES55 {
background-color: var(--clr-79969);
color: #ffffff;
padding: 15px;

 }
.MES55 {
background-color: var(--clr-79969);
color: #ffffff;
padding: 15px;

h1.MEC55, h2.MEC55, h3.MEC55, h4.MEC55, h5.MEC55, h6.MEC55 { color: #ffffff;
 }
h1.MEC55 { @media #{$medium-up} { font-size: 48px; }; }
h1.MEC55 { @media #{$large-up} { font-size: 50px; }; }
h2.MEC55 { @media #{$medium-up} { font-size: 42px; }; }
h2.MEC55 { @media #{$large-up} { font-size: 43.75px; }; }
h3.MEC55 { @media #{$medium-up} { font-size: 36px; }; }
h3.MEC55 { @media #{$large-up} { font-size: 37.5px; }; }
h4.MEC55 { @media #{$medium-up} { font-size: 30px; }; }
h4.MEC55 { @media #{$large-up} { font-size: 31.25px; }; }
h5.MEC55 { @media #{$medium-up} { font-size: 24px; }; }
h5.MEC55 { @media #{$large-up} { font-size: 25px; }; }
h6.MEC55 { @media #{$medium-up} { font-size: 21.6px; }; }
h6.MEC55 { @media #{$large-up} { font-size: 22.5px; }; }
 }
cite.MEC55{
color: #ffffff;
}
/* Shop Menu:56 */
nav.me-Menu.MES56 {
& .menu-item.MEC56, & .menu-item.MEC56 > div.MEC56{ & > a.MEC56{color: var(--clr-79945);
text-transform: uppercase;
}
 &:hover > a.MEC56{color: var(--clr-79949);
}
 }
&.horizontal > .menu-item.MEC56 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC56 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC56 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC56 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}

& > .menu-item.MEC56.active { & > a{ color: var(--clr-79945);}
 }
&.horizontal .menu-item.MEC56:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-79969-flat);}}
&.vertical .menu-item.MEC56:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-79969-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC56{background-color: var(--clr-79969); &:hover {background-color: 4;}
}
& .menu-item.MEC56, & .menu-item.MEC56 > div.MEC56{ & > a.MEC56{color: #ffffff;
}
  }

}
}
 }
/* Thin Border:57 */
.MES57 {
border-width: 1px;
border-style: solid;
border-color: var(--clr-79947);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES57 {
border-width: 1px;
border-style: solid;
border-color: var(--clr-79947);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* :64 */
.MES64 {
background-color: #9eb2c0;
@media #{$large-up} {
min-height: 90px;};
 }
.MES64 {
background-color: #9eb2c0;
@media #{$large-up} {
min-height: 90px;};
 }
/* :58 */
.MES58 {
min-height:100px;
@media #{$medium-up} {
min-height: 100px;};
@media #{$large-up} {
min-height: 100px;};
 }
.MES58 {
min-height:100px;
@media #{$medium-up} {
min-height: 100px;};
@media #{$large-up} {
min-height: 100px;};
h1.MEC58, h2.MEC58, h3.MEC58, h4.MEC58, h5.MEC58, h6.MEC58 { color: #1a1918;
 }
&:hover { h1.MEC58, h2.MEC58, h3.MEC58, h4.MEC58, h5.MEC58, h6.MEC58 { color: var(--clr-79949);} }
 }
/* Page Accordion:59 */
details.MES59 {
 }
/* Grid with hover:61 */
.MES61 {
&:hover, &.hover { background-color: #c0c0c07b;}
 }
.MES61 {
&:hover, &.hover { background-color: #c0c0c07b;}
 }
/* :62 */
.MES62 {
 }
.MES62 {
&:hover { h1.MEC62, h2.MEC62, h3.MEC62, h4.MEC62, h5.MEC62, h6.MEC62 { color: #808080;} }
 }
/* :63 */
.MES63 {
background-color: #002366;
border-top-style: inset;
border-right-style: inset;
border-bottom-style: inset;
border-left-style: inset;
 }
.MES63 {
background-color: #002366;
border-top-style: inset;
border-right-style: inset;
border-bottom-style: inset;
border-left-style: inset;
 }
/* Footer:65 */
.MES65 {
background-color: #545454;
color: #ffffff;
@media #{$large-up} {
min-height: 80px;};
 }
.MES65 {
background-color: #545454;
color: #ffffff;
@media #{$large-up} {
min-height: 80px;};
 }
a.MEC65 { color: #ffffff;
 }
cite.MEC65{
color: #ffffff;
}
/* Intro Video:66 */
.MES66 {
 }
.MES66 {
 }
/* transparent with navy text:67 */
.MES67 {
color: #001a4b;
 }
.MES67 {
color: #001a4b;
 }
cite.MEC67{
color: #001a4b;
}
/* Explore Buttons:68 */
.MES68 {
color: #001a4b;
&:hover { color: #ffffff;}
border-width: 0 0 2px 0;
border-style: solid;
border-color: #001a4b00 #001a4b00 #001a4b #001a4b00;
 }
